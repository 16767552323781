import styled from "@emotion/styled";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import Heading from "../Heading";
import Image from "../ImageComponent";
import Introduction from "../Introduction";
import Text from "../Text";

const ProcessStepText = styled.div`
  ${mq("2")} {
    padding-left: ${({ hasPadding }) => (hasPadding ? "100px" : "0")};
  }
`;

const ProcesStepImages = styled(motion.div)`
  margin-top: ${theme.spacing("l")};
  margin-bottom: ${({ multipleImages }) =>
    multipleImages ? theme.spacing("xxl") : theme.spacing("s")};
  position: relative;

  > *:first-of-type:not(:only-child) {
    width: 80%;
  }

  > *:nth-of-type(2) {
    right: 0;
    width: 55%;
    position: absolute;
    bottom: -${theme.spacing("xl")};

    ${mq("2")} {
      width: 40%;
    }
  }
`;

function ProcessStep({ step, visible }) {
  const isSSR = typeof window === "undefined";

  const groupVariants = {
    show: theme.get("animation.staggeredReveal"),
    hide: theme.get("animation.staggeredHide"),
  };

  const staggerVariants = {
    start: {
      y: 60,
      opacity: 0,
    },
    hide: {
      y: -60,
      opacity: 0,
    },
    show: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div>
      {step.content.map((contentItem, index) => (
        <motion.div
          key={index}
          animate={visible ? "show" : "hide"}
          variants={groupVariants}
          initial={isSSR ? "show" : "start"}
        >
          <ProcessStepText hasPadding={index % 2 === 0}>
            {contentItem.title && (
              <motion.div variants={staggerVariants}>
                <Heading size={3} color={"primary"}>
                  {contentItem.title}
                </Heading>
              </motion.div>
            )}

            <motion.div variants={staggerVariants}>
              <Introduction>{contentItem.intro}</Introduction>
            </motion.div>

            <motion.div variants={staggerVariants}>
              <Text>{contentItem.text}</Text>
            </motion.div>
          </ProcessStepText>
          <ProcesStepImages
            variants={staggerVariants}
            multipleImages={contentItem.images.length > 1}
          >
            {contentItem.images.map((image, index) => (
              <div key={index}>
                <Image {...image} />
              </div>
            ))}
          </ProcesStepImages>
        </motion.div>
      ))}
    </div>
  );
}

ProcessStep.propTypes = {
  step: PropTypes.object,
  visible: PropTypes.bool,
};

export default ProcessStep;
