import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import Icon from "../Icon";
import Link from "../Link";
import { ctaLarge } from "../Typography";

const StyledPageLink = styled(Link)`
  ${ctaLarge};
  display: block;

  color: ${({ colorstyle }) =>
    theme.color(colorstyle ? colorstyle : "secondary")};

  ${mq("3")} {
    max-width: unset;
  }

  &:hover {
    color: ${({ colorstyle }) =>
      theme.color(colorstyle ? colorstyle : "primary")};
  }

  &:hover svg {
    transform: translateX(20px);
    color: ${({ colorstyle }) =>
      theme.color(colorstyle ? colorstyle : "primary")};
  }
`;

const StyledIcon = styled(Icon)`
  height: ${theme.space("s")};
  width: ${theme.space("s")};
  margin-left: 12px;
  top: 5px;

  ${mq("2")} {
    height: ${theme.space("m")};
    width: ${theme.space("m")};
    margin-left: 16px;
    top: 7px;
  }

  position: relative;
  color: ${({ colorstyle }) =>
    theme.color(colorstyle ? colorstyle : "secondary.base")};
  transition: ${theme.transition()};
`;

function PageLink({ title, url, color, ...props }) {
  return (
    <StyledPageLink colorstyle={color} to={url} {...props}>
      {title} <StyledIcon colorstyle={color} type="arrow" />
    </StyledPageLink>
  );
}

PageLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default PageLink;
