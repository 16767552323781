import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import ReactPlayer from "react-player/lazy";

const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

function VideoPlayer({ url, light, loop, autoplay, controls, ...props }) {
  return (
    <Player
      url={url}
      light={light}
      width="100%"
      height="100%"
      muted={autoplay}
      playing={autoplay}
      controls={controls}
      loop={loop}
      playsinline
      {...props}
    />
  );
}

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  light: PropTypes.bool,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  controls: PropTypes.bool,
};
export default VideoPlayer;
