import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import VideoPlayer from "../VideoPlayer";

const ImageFallbackWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  > img {
    width: 100%;
    height: 100%;
    filter: blur(50px);
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
`;

function MuxVideo({ video, ...props }) {
  const placeholderImage = `${video.video.thumbJpg}?width=50&height=50&fit_mode=preserve&time=0`;
  return (
    <VideoWrapper
      style={{
        paddingTop: `${(video.height / video.width) * 100}%`,
      }}
      {...props}
    >
      <ImageFallbackWrapper
        style={{ backgroundImage: `url(${placeholderImage})` }}
      >
        <img src={placeholderImage} alt={video.alt} />
      </ImageFallbackWrapper>
      <VideoPlayer
        className={"videoPlayer"}
        url={video.video.streamingUrl}
        autoplay
        loop
      />
    </VideoWrapper>
  );
}

MuxVideo.propTypes = {
  video: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    alt: PropTypes.string,
    video: PropTypes.shape({
      streamingUrl: PropTypes.string,
      thumbJpg: PropTypes.string,
    }),
  }).isRequired,
};
export default MuxVideo;
