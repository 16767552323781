import styled from "@emotion/styled";
import { motion, useAnimation } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

import theme from "../../theme";

const MAX_STEPS = 5;

const rotationSteps = [0, 52, -30, -110, -210, 0];

const StyledSVG = styled(motion.svg)`
  position: relative;
  transform-origin: center center !important; // important is unfortunately needed, because Framer overwrites this during animation
`;

const StyledGroup = styled.g`
  transition: fill 0.3s ease;

  stroke: ${({ step, activeStep }) =>
    step === activeStep || activeStep === MAX_STEPS
      ? theme.color("primary")
      : theme.color("dark.base")};

  fill: ${({ step, activeStep }) =>
    step === activeStep || activeStep === MAX_STEPS
      ? theme.color("primary")
      : theme.color("blue.light")};
`;

const ScaleContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  transition: transform 250ms ease-in;
  transform-origin: center center;
  transform: ${({ position }) => handlePosition(position)};
`;

const handlePosition = (position) => {
  switch (position) {
    case "top":
      return "scale(1) translate(0px, -20%)";
    case "center":
      return "scale(2) translate(-35%, 0px)";
    case "bottom":
      return "scale(1) translate(0px,20%)";
    default:
      return "scale(1) translate(0px,0px)";
  }
};
/* eslint-disable */

function ProcessCircle({ currentStep, animateSize, ...props }) {
  const [activeStep, setActiveStep] = useState(0);
  const [circlePosition, setCirclePosition] = useState(0);
  const circleControls = useAnimation();
  const groupRef = useRef();

  useEffect(() => {
    circleControls.start({
      rotate: -360,
      transition: { repeat: Infinity, duration: 25, ease: "linear" },
    });
  }, []);

  useEffect(() => {
    setActiveStep(currentStep);

    if (!animateSize) {
      return;
    }

    const positionCircle = (position) => {
      setCirclePosition(position);
    };

    const rotateCircle = (to, duration) => {
      circleControls.start({
        rotate: to,
        transition: { repeat: Infinity, duration: duration, ease: "linear" },
      });
    };

    const rotateToStep = async (step) => {
      circleControls.set({
        rotate: getElementRotation(groupRef.current) % 360,
      });

      await circleControls.start({
        rotate: rotationSteps[step],
        transition: { duration: 1.5, ease: "easeOut" },
      });
      rotateCircle(rotationSteps[step] - 360, 250);
    };

    currentStep !== activeStep && circleControls.stop();

    if (currentStep === 0) {
      rotateCircle(-360, 50);
      positionCircle("top");
    } else if (currentStep === MAX_STEPS) {
      rotateCircle(-360, 50);
      positionCircle("bottom");
    } else {
      rotateToStep(currentStep);
      (activeStep === 0 || activeStep === MAX_STEPS) &&
        positionCircle("center");
    }
  }, [currentStep, circleControls]);

  return (
    <div {...props}>
      <ScaleContainer position={circlePosition}>
        <StyledSVG
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 518.170 519.2'
          ref={groupRef}
          animate={circleControls}
        >
          <g>
            <StyledGroup id='ontdekken' step={1} activeStep={activeStep}>
              <path d='M299.12,23.54c-1.21,12.7-8.37,19.24-19.05,18.22S263.55,33,264.76,20.25,273.18,1,283.87,2,300.33,10.89,299.12,23.54Zm-21.08-2c-.63,6.58.45,9.31,3,9.56s4.12-2.23,4.75-8.81-.39-9.3-3-9.55S278.67,14.94,278,21.52Z' />
              <path d='M328.21,10.79l11.73,3.05-9.74,37.5L319.25,48.5,314.58,30l-.17,0-4.2,16.17-11.73-3,9.74-37.5,11.51,3L323.88,27l.11,0Z' />
              <path d='M340,55.53,351.2,29.71,342.68,26l4.21-9.74,28.91,12.5-4.21,9.74-8.52-3.68L351.9,60.67Z' />
              <path d='M382.24,32.17l12.88,8.06c9.24,5.78,11.71,14.85,4.82,25.86s-16.17,12.73-25.37,7L361.69,65Zm-4,30.62,1.47.92c2.9,1.82,5.56.4,8.79-4.76s3.34-8.17.48-10l-1.52-1Z' />
              <path d='M410.54,100.42l-20-16.88,25-29.59,19.89,16.81-6.81,8.06-10.28-8.68-2.54,3,9,7.64-6.14,7.26-9-7.64L407,83.6l10.37,8.76Z' />
              <path d='M425.36,116.54l7-13.48-.89-1-10.61,9.45-8.64-9.7,28.94-25.76,8.64,9.7-10.22,9.1,1,1.08,13.58-5.33,9.37,10.53-19.32,6.64-9.15,19.64Z' />
              <path d='M445.58,144.14l9-12.23-.72-1.12-11.95,7.69-7-10.93,32.59-21,7,10.93L463,124.92l.78,1.22L478,123l7.63,11.85-20.12,3.57-12.09,18Z' />
              <path d='M464.05,180.49l-10.87-23.8,35.25-16.09,10.82,23.69-9.6,4.39-5.59-12.24-3.59,1.64,4.92,10.76-8.65,3.95L471.82,162l-3.8,1.73,5.64,12.35Z' />
              <path d='M508,191.47l3.38,11.64-37.2,10.8L471,203.05l13.48-13.46-.05-.17-16,4.66L465,182.44l37.2-10.8,3.32,11.42-13.69,13,0,.11Z' />
            </StyledGroup>
            <StyledGroup id='ontwerpen' step={2} activeStep={activeStep}>
              <path d='M498.26,257.86c-12.72,1-20.4-4.93-21.23-15.63s5.83-17.79,18.55-18.78,20.4,5,21.24,15.69S510.92,256.88,498.26,257.86Zm-1.64-21.1c-6.59.51-9.09,2.05-8.89,4.65s2.9,3.67,9.5,3.16,9.1-2,8.89-4.59S503.21,236.24,496.62,236.76Z' />
              <path d='M515.87,284.23l-1,12.08-38.62-3.05.89-11.27,17.37-7.82V274l-16.65-1.31.95-12.08,38.62,3.05-.94,11.85-17.39,7.3v.12Z' />
              <path d='M473.91,303.71l27.38,6.46,2.13-9,10.33,2.44-7.23,30.65-10.33-2.44,2.13-9-27.38-6.46Z' />
              <path d='M493.75,366.81l-19.08-5.28,0,.05,16.82,10.49-4.88,11.29L454.13,360.8l5-11.66,18.83,5,.07-.16-16.55-10.25L466.58,332l38.67,8.18-5.06,11.71L481,346.77l-.07.16,16.94,10.23Z' />
              <path d='M435.91,389.71l14.23-21.95,32.5,21.08-14.17,21.85L459.61,405l7.32-11.29-3.31-2.15-6.44,9.93-8-5.18,6.44-9.93-3.5-2.27-7.38,11.39Z' />
              <path d='M421.68,406.54a9.44,9.44,0,0,1,4.48,1.83l4,2.89c1.42.93,2.57.93,3.52-.16l1.68-1.92-9.1-7.93,8.42-9.66L463.91,417l-11.54,13.25c-5.6,6.43-12,8.39-18.3,2.9-3.94-3.43-4.29-8.05-2.63-11.37l-.09-.08a6.61,6.61,0,0,1-7.92.26l-5.84-3.63a9.94,9.94,0,0,0-4.91-1.51Zm24,15.7,2-2.32-5-4.34-2,2.32c-1.14,1.31-1,2.58.78,4.14S444.48,423.64,445.7,422.24Z' />
              <path d='M409.38,433.51l-7-8.17,9.85-8.39,25.12,29.5-12.5,10.64c-6.67,5.68-13.89,6-20.1-1.32s-4.34-14.67,1.93-20Zm9.87,15,1.68-1.43-5-5.92-1.68,1.43c-1.15,1-1.39,2.48.49,4.69S418.05,449.51,419.25,448.49Z' />
              <path d='M365.75,451.49l22-14.08,20.86,32.65-21.94,14L381,475.19,392.37,468l-2.12-3.32-10,6.37-5.12-8,10-6.37-2.25-3.52-11.44,7.3Z' />
              <path d='M361,496.56l-11.05,5L334,466.21l10.31-4.65L359.57,473l.16-.07-6.86-15.23,11.05-5,15.91,35.33L369,493l-14.76-11.74-.11,0Z' />
            </StyledGroup>
            <StyledGroup id='ontwikkelen' step={3} activeStep={activeStep}>
              <path d='M293.88,496.2c-2.75-12.46,2-20.89,12.52-23.2s18.43,3.29,21.18,15.76S325.48,509.66,315,512,296.62,508.6,293.88,496.2Zm20.68-4.57c-1.43-6.46-3.29-8.72-5.84-8.16s-3.23,3.39-1.81,9.84,3.24,8.73,5.79,8.17S316,498.09,314.56,491.63Z' />
              <path d='M270.21,517.27l-12.1.72-2.29-38.67,11.29-.67,10.14,16.13h.17l-1-16.67,12.1-.72,2.29,38.67-11.87.7-9.62-16.22h-.12Z' />
              <path d='M245.1,478.39l-2.58,28,9.24.85-1,10.57-31.36-2.88,1-10.57,9.24.85,2.58-28Z' />
              <path d='M185.42,506.88,188,487.25l-.06,0-8,18.13L168,502.11l17.79-35.3,12.25,3.35-2.29,19.34.17.05,7.83-17.82,12.31,3.37-2.68,39.43-12.31-3.37,2.38-19.67-.17-.05-7.75,18.2Z' />
              <path d='M151,493.92l15.34-35.58,11.93,5.14-15.34,35.58Z' />
              <path d='M146.52,447.76l-2.41,15,1.16.67,7.09-12.31,11.26,6.48-19.33,33.58L133,484.7l6.83-11.86-1.26-.72-11.2,9.35-12.21-7L131.43,462l2.48-21.52Z' />
              <path d='M118.63,428l-4.71,14.45,1,.84,8.91-11.07L134,440.33l-24.3,30.17-10.12-8.15,8.58-10.66-1.13-.91-12.51,7.49-11-8.84,18-9.73,5.79-20.88Z' />
              <path d='M89.58,399.3l17.84,19.13L79.08,444.86,61.31,425.81l7.72-7.2,9.18,9.84,2.88-2.69L73,417.11l7-6.49,8.07,8.65,3-2.85-9.26-9.93Z' />
              <path d='M49.12,409.56l22.82-16.45-7.23-10,8.61-6.21,14.82,20.56L56.72,420.1Z' />
              <path d='M60,352.5l12.5,23L38.42,394,26,371.12l9.27-5.05,6.43,11.82L45.13,376l-5.66-10.39,8.36-4.55,5.66,10.39,3.67-2-6.49-11.92Z' />
              <path d='M15.34,344.6l-4.19-11.38,36.36-13.38,3.91,10.61L38.91,344.82,39,345l15.67-5.77,4.19,11.38L22.47,364l-4.11-11.16,12.74-13.9,0-.11Z' />
            </StyledGroup>
            <StyledGroup id='optimaliseren' step={4} activeStep={activeStep}>
              <path d='M20.41,277.69c12.62-1.87,20.69,3.5,22.27,14.11s-4.58,18.15-17.2,20-20.7-3.55-22.27-14.17S7.85,279.56,20.41,277.69Zm3.11,20.94c6.54-1,8.93-2.67,8.54-5.26s-3.15-3.46-9.69-2.49-8.94,2.62-8.55,5.2S17,299.6,23.52,298.63Z' />
              <path d='M29,260.53l10.73.17-.21,12.93L.74,273,1,256.59c.14-8.76,4.7-14.37,14.27-14.21,9.4.15,13.88,6.43,13.74,14.67ZM11.2,258l0,2.2,7.77.13,0-2.2c0-1.51-.94-2.68-3.84-2.73S11.22,256.47,11.2,258Z' />
              <path d='M41.13,233.61l-27.83-4.1L12,238.69l-10.5-1.55L6,206l10.5,1.55-1.35,9.18L43,220.82Z' />
              <path d='M11,187.58l37.48,9.83L45.23,210,7.75,200.15Z' />
              <path d='M48.4,171.18l-2.3,5.7-9.5-.33-.06.16,7.68,3,9,3.63-4.58,11.35-35.93-14.5,5-12.32,14,0,.07-.16-10-9.81,4.93-12.21,35.93,14.5L58,171.49l-9-3.63-7.59-3.19-.07.16Z' />
              <path d='M69.54,137.28,64.78,145l4.44,4.39-6.54,10.55L35.49,130.24l8.09-13.06,38.68,11.14-6.78,10.94Zm-11.49.93,2.47-4L51,131l-.06.1Z' />
              <path d='M61.55,93.35,83.12,111.4l7.93-9.47,8.14,6.81L82.93,128.18,53.22,103.32Z' />
              <path d='M82.83,70.82,110,98.46l-9.27,9.11L73.56,79.92Z' />
              <path d='M104.51,90.75l5.88-10a4.35,4.35,0,0,0,5.83.25c1.13-.9,1.59-2,.87-2.92-2.68-3.36-14.56,9-22.94-1.52-5-6.26-2.73-14.22,3.85-19.46s12.61-5.3,17.55-1.15l-6.82,9.22a3.46,3.46,0,0,0-4.66-.44c-1,.83-1.29,1.92-.6,2.78,2.53,3.18,14.69-9.11,23.3,1.68,5.06,6.35,2.14,14.32-4.4,19.52C116.06,93.7,109.34,94.39,104.51,90.75Z' />
              <path d='M157.59,64.54,135.17,78,115.22,44.8l22.32-13.41,5.43,9-11.53,6.93,2,3.38,10.14-6.09,4.9,8.15L138.37,58.9l2.15,3.58,11.63-7Z' />
              <path d='M177.79,55.66A9.53,9.53,0,0,1,174.57,52l-2.34-4.36c-.87-1.46-1.9-2-3.24-1.41l-2.35,1,4.68,11.12-11.81,5-15-35.71,16.2-6.82c7.86-3.31,14.47-2.25,17.71,5.44,2,4.81.32,9.12-2.64,11.37l0,.11A6.6,6.6,0,0,1,183,41l3.65,5.83a9.92,9.92,0,0,0,3.75,3.52ZM163.12,31l-2.83,1.19,2.57,6.09,2.83-1.19c1.6-.68,2-1.87,1.12-4.06S164.83,30.28,163.12,31Z' />
              <path d='M216.22,43.94l-25.31,6.61-9.79-37.49,25.2-6.58L209,16.69l-13,3.4,1,3.82,11.45-3,2.41,9.2-11.45,3,1.06,4,13.13-3.43Z' />
              <path d='M234.59,2.54l12-1.33,4.26,38.51L239.66,41,228.71,25.37l-.17,0L230.38,42l-12,1.33L214.07,4.81,225.89,3.5l10.43,15.71h.12Z' />
            </StyledGroup>
          </g>
        </StyledSVG>
      </ScaleContainer>
    </div>
  );
}
/* eslint-enable */

export default ProcessCircle;

function getElementRotation(el) {
  const style = window.getComputedStyle(el, null);
  let angle = 0;
  const transform =
    style.getPropertyValue("-webkit-transform") ||
    style.getPropertyValue("-moz-transform") ||
    style.getPropertyValue("-ms-transform") ||
    style.getPropertyValue("-o-transform") ||
    style.getPropertyValue("transform");

  if (transform) {
    const regExp = /\(([^)]+)\)/;
    const matrixValue = regExp.exec(transform);
    if (matrixValue && matrixValue[1]) {
      const transformValues = matrixValue[1].split(",");
      const radians = Math.atan2(transformValues[1], transformValues[0]);
      angle = Math.round(radians * (180 / Math.PI));
    }
  }

  return angle;
}
