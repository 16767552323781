import { useEffect, useState } from "react";

export default function useInViewport(
  ref,
  rootMargin = "0px",
  unobserveOnEnter = false
) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (!ref) {
      return false;
    }
    const refElement = ref.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        setIntersecting(e.isIntersecting);
        unobserveOnEnter && e.isIntersecting && observer.unobserve(refElement);
      },
      {
        rootMargin,
      }
    );

    if (refElement) {
      observer.observe(refElement);
    }

    return () => {
      if (refElement) {
        observer.unobserve(refElement);
      }
    };
  }, [ref, rootMargin, unobserveOnEnter]);

  return isIntersecting;
}
