import styled from "@emotion/styled";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import useInViewport from "../../hooks/useInViewport";
import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import ContentWrapper from "../ContentWrapper";
import Heading from "../Heading";
import Icon from "../Icon";
import Introduction from "../Introduction";
import { ctaLarge } from "../Typography";
import ProcessCircle from "./ProcessCircle";
import ProcessStep from "./ProcessStep";

const StyledArea = styled.div`
  position: relative;
  padding-bottom: ${theme.space("l")};
  border-left: 0px solid ${theme.color("blue.base")};

  ${mq("2")} {
    padding-bottom: 250px;
    padding-left: ${theme.space("l")};
    border-width: 1px;

    &:last-of-type {
      padding-left: 0;
      border-width: 0;
      padding-bottom: ${theme.space("xxxl")};
      padding-top: ${theme.space("xxl")};
    }
  }
`;

const ViewportArea = ({ children, onInViewChanged }) => {
  const ref = useRef();
  const inView = useInViewport(ref, "50% 0px -30% 0px");

  useEffect(() => {
    onInViewChanged(inView);
    // eslint-disable-next-line
  }, [inView]);

  return <StyledArea ref={ref}>{children}</StyledArea>;
};

ViewportArea.propTypes = {
  children: PropTypes.node.isRequired,
  onInViewChanged: PropTypes.func,
};

const FullWidthWrapper = styled.div`
  width: 100%;
  padding: ${theme.space("xs")} 0;
  background-color: ${theme.color("blue.light")};

  ${mq("2")} {
    padding: ${theme.space("xxl")} ${theme.space("l")};
  }
`;

const StyledProcessCircle = styled(ProcessCircle)`
  display: none;

  ${mq("2")} {
    display: block;
    width: 100%;
    position: relative;
  }
`;

const StyledProcessStatic = styled(ProcessCircle)`
  width: 60%;
  margin: ${theme.spacing("m")} auto;
  ${mq("2")} {
    display: none;
  }
`;

const StepIndicator = styled.div`
  display: none;
  position: absolute;
  top: 3px;
  left: 0;

  padding-left: ${theme.space("xs")};
  padding-top: 3px;
  font-size: ${theme.fs("caption")};
  font-family: ${theme.family("primary")};
  font-weight: bold;
  color: ${theme.color("blue.base")};

  transition: opacity 250ms ease-in-out;

  ${mq("2")} {
    display: block;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
  }

  &:before {
    content: " ";
    position: absolute;
    width: ${theme.space("xxs")};
    height: ${theme.space("xxs")};
    top: ${theme.space("xxs")};
    left: -4px;

    border-radius: 50%;
    background-color: ${theme.color("blue.base")};
  }
`;

const ProcessContent = styled.div`
  ${mq("2")} {
    margin-left: 50%;
  }

  > *:first-of-type {
    max-width: 420px;
  }
`;

const Action = styled.span`
  ${ctaLarge};
  display: block;

  color: ${theme.color("secondary")};

  &:hover,
  &:hover svg {
    color: ${theme.color("primary")};
  }

  transition: ${theme.transition()};
`;

const StyledIcon = styled(Icon)`
  position: relative;
  top: 5px;
  height: ${theme.space("s")};
  width: ${theme.space("s")};
  margin-left: ${theme.space("xs")};

  ${mq("2")} {
    height: ${theme.space("m")};
    width: ${theme.space("m")};
    margin-left: ${theme.space("xs")};
    top: 7px;
  }

  color: ${theme.color("secondary")};
  transition: ${theme.transition()};
`;

const CircleWrapper = styled.div`
  display: none;
  ${mq("2")} {
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    top: 0;
    margin: 0 auto;
    width: 50%;
    float: left;
    padding: 0 ${theme.space("xxl")} 0 0;
  }
`;

function ProcessVizualizer({ intro, steps, payOff }) {
  const isSSR = typeof window === "undefined";

  const [activeStep, setActiveStep] = useState(0);
  const [stepsInView, setStepsInView] = useState(
    new Array(steps.length + 2).fill(false)
  );

  const mainRef = useRef();
  const finalStep = steps.length + 1;

  function backTotop() {
    mainRef.current.scrollIntoView({ behavior: "smooth" });
  }

  function checkActiveStep(stepIndex, stepInView) {
    const currentSteps = [...stepsInView];
    currentSteps[stepIndex] = stepInView;
    setStepsInView(currentSteps);

    let activeStep = Math.max(0, currentSteps.lastIndexOf(true));
    setActiveStep(activeStep);
  }

  const variants = {
    hide: {
      y: 60,
      opacity: 0,
      transition: theme.get("animation.reveal.transition"),
    },
    show: {
      y: 0,
      opacity: 1,
      transition: theme.get("animation.reveal.transition"),
    },
  };

  return (
    <FullWidthWrapper ref={mainRef}>
      <ContentWrapper columns={12}>
        <CircleWrapper>
          <StyledProcessCircle animateSize currentStep={activeStep} />
        </CircleWrapper>
        <ProcessContent>
          <ViewportArea
            onInViewChanged={(inView) => checkActiveStep(0, inView)}
          >
            <motion.div
              animate={activeStep === 0 ? "show" : "hide"}
              variants={variants}
              initial={isSSR ? "show" : "hide"}
            >
              <StyledProcessStatic currentStep={0} />
              <Heading color={"primary"} size={2}>
                {intro.title}
              </Heading>
              <Introduction>{intro.text}</Introduction>
              <Action>
                {intro.action}
                <StyledIcon
                  type="arrow"
                  style={{ transform: "rotate(90deg)" }}
                />
              </Action>
            </motion.div>
          </ViewportArea>

          {steps.map((step, i) => {
            const currentIndex = i + 1;
            return (
              <ViewportArea
                key={currentIndex}
                onInViewChanged={(inView) =>
                  checkActiveStep(currentIndex, inView)
                }
              >
                <StepIndicator visible={activeStep === currentIndex}>
                  0{currentIndex}
                </StepIndicator>
                <StyledProcessStatic currentStep={currentIndex} />
                <ProcessStep
                  visible={activeStep === currentIndex}
                  step={step}
                />
              </ViewportArea>
            );
          })}

          <ViewportArea
            onInViewChanged={(inView) => checkActiveStep(finalStep, inView)}
          >
            <motion.div
              animate={activeStep === finalStep ? "show" : "show"}
              variants={variants}
              initial={isSSR ? "show" : "show"}
            >
              <StyledProcessStatic currentStep={finalStep} />
              <Heading color={"primary"} size={2}>
                {payOff.title}
              </Heading>
              <Introduction>{payOff.text}</Introduction>
              <Action onClick={backTotop}>
                {payOff.action}
                <StyledIcon
                  type="arrow"
                  style={{ transform: "rotate(-90deg)" }}
                />
              </Action>
            </motion.div>
          </ViewportArea>
        </ProcessContent>
      </ContentWrapper>
    </FullWidthWrapper>
  );
}

ProcessVizualizer.propTypes = {
  intro: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    action: PropTypes.string,
  }),
  payOff: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    action: PropTypes.string,
  }),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      content: PropTypes.array,
    })
  ),
};

export default ProcessVizualizer;
