import styled from "@emotion/styled";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { prop } from "ramda";
import React from "react";

import CaseGrid from "../components/CaseGrid";
import ContactHighlight from "../components/ContactHighlight";
import ContentWrapper from "../components/ContentWrapper";
import Heading from "../components/Heading";
import Hero from "../components/Hero/v7";
import Introduction from "../components/Introduction";
import List from "../components/List";
import PageLink from "../components/PageLink";
import ProcessCircle from "../components/ProcessVisualizer";
import SEO from "../components/SEO";
// import Statements from "../components/Statements/v2";
import { PRODUCTS_PATH } from "../constants";
import theme from "../theme";
import { product, whatWeDo } from "../types";
import createLink from "../utils/createLink";
import formatCase from "../utils/formatters/formatCase";
import hexToRgba from "../utils/hexToRgba";
import mq from "../utils/mediaQuery";

const CaseGridWrapper = styled.div`
  background: linear-gradient(
    180deg,
    ${hexToRgba(1, theme.color("blue.light"))} 50%,
    ${hexToRgba(0, theme.color("blue.light"))} 50%
  );
`;

const ProductenWrapper = styled(ContentWrapper)`
  display: grid;
  grid-template-columns: 1fr;
  ${mq("2")} {
    grid-template-columns: 1fr 1fr;
  }

  ${mq("3")} {
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: ${theme.spacing("xxxl")};
  }
`;

const WhatWeDo = ({
  data: {
    data: { whatWeDo, allProducts },
  },
}) => {
  const designProcesses = whatWeDo.designProcesses.map((process) => ({
    ...process,
    image: process.image.url,
    designTasks: process.designTasks.split(","),
  }));

  const formattedRelatedCases = whatWeDo.relatedCases.map((caseItem) =>
    formatCase(caseItem)
  );

  const processIntro = {
    title: whatWeDo.processIntroTitle,
    text: whatWeDo.processIntroText,
    action: whatWeDo.processIntroAction,
  };

  const processPayoff = {
    title: whatWeDo.processPayoffTitle,
    text: whatWeDo.processPayoffText,
    action: whatWeDo.processPayoffAction,
  };

  return (
    <>
      <SEO tags={prop("seoMetaTags", whatWeDo)} />

      <Hero
        title={prop("title", whatWeDo)}
        image={prop("thumbnail", whatWeDo)}
        introduction={
          <Introduction color={"text.light"}>
            {prop("tagline", whatWeDo)}
          </Introduction>
        }
      />

      <ProductenWrapper columns={10}>
        {allProducts.map((product, index) => (
          <List
            key={index}
            vertical
            bulleted={true}
            items={product.methods.split(",").slice(0, 5)}
            heading={product.title}
            link={createLink(`${PRODUCTS_PATH}/${product.slug}`)}
          />
        ))}
      </ProductenWrapper>

      <ProcessCircle
        intro={processIntro}
        payOff={processPayoff}
        steps={designProcesses}
      ></ProcessCircle>

      {formattedRelatedCases.length > 0 && (
        <CaseGridWrapper>
          <CaseGrid
            heading={
              <>
                <Heading style={{ marginBottom: theme.spacing("m") }} size={2}>
                  Het resultaat van onze werkwijze?
                </Heading>
                <PageLink
                  url={"/werk"}
                  title={"Laat je inspireren door ons werk"}
                />
              </>
            }
            items={formattedRelatedCases}
          />
        </CaseGridWrapper>
      )}

      <ContactHighlight {...prop("contactHighlight", whatWeDo)} />
    </>
  );
};

WhatWeDo.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      whatWeDo: PropTypes.shape(whatWeDo),
      allProducts: PropTypes.arrayOf(PropTypes.shape(product)),
    }),
  }),
};

export default WhatWeDo;

export const query = graphql`
  query WhatWeDoQuery {
    data: dato {
      allProducts {
        id
        title
        methods
        slug
      }
      whatWeDo {
        seoMetaTags: _seoMetaTags {
          tag
          attributes
          content
        }
        title
        tagline
        thumbnail {
          data: responsiveImage(
            imgixParams: { fit: crop, h: 710, auto: format }
          ) {
            ...responsiveImageFragment
          }
        }
        designProcesses {
          image {
            url
            alt
          }
          title
          description
          designTasks
          content {
            title
            intro
            text
            images {
              data: responsiveImage(
                imgixParams: { fit: crop, w: 604, auto: format }
              ) {
                ...responsiveImageFragment
              }
            }
          }
        }
        contactHighlight {
          ...contactHighlightFragment
        }
        relatedCases {
          ...caseFragment
        }
        domainsTitle
        domainsCta
        domainsBackgroundtitle
        domainsStatements {
          title
        }
        processIntroTitle
        processIntroText
        processIntroAction
        processPayoffTitle
        processPayoffText
        processPayoffAction
      }
    }
  }
`;
