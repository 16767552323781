import styled from "@emotion/styled";
import { bool, node } from "prop-types";
import React from "react";

import { bodyLarge } from "../Typography";

const Wrapper = styled.p`
  ${bodyLarge};
`;

function Introduction({ children, html = false, ...props }) {
  return html ? (
    <Wrapper
      as={html ? "div" : "p"}
      {...props}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  ) : (
    <Wrapper {...props}>{children}</Wrapper>
  );
}

Introduction.propTypes = {
  children: node.isRequired,
  small: bool,
  html: bool,
};

Introduction.defaultProps = {
  small: false,
};

export default Introduction;
