import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";

const Wrapper = styled.div`
  margin-top: ${theme.space("xl")};

  ${mq("2")} {
    margin-top: ${theme.space("xxl")};
  }

  ${mq("3")} {
    margin-top: ${theme.space("xxxl")};
  }
`;

function VerticalMargin({ children, ...props }) {
  return <Wrapper {...props}>{children}</Wrapper>;
}

VerticalMargin.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VerticalMargin;
